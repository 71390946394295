import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import {MicroCard, MiniCard, Card} from '../components/Card';

import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';

import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import TransformImage from '../assets/images/icon-transform.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';

const herobg = '#1c2841', herofcolor='#efefef';
const reachCloutics = <p style={{textAlign:"center", color:herobg, fontWeight:800}}>+1 732 734 6130 &nbsp;&nbsp;&nbsp; solve (at) cloutics (dot) com</p>


const AboutPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                <h3 >About Us</h3>
                <h1 style={{letterSpacing:'.1em'}}>We accelerate innovation, supplement talent and
                    transform business processes.
                </h1>
                <h3 >Our investment is on Process, Talent & Technology </h3>
            </header>
        </div>
        


        <div className="inner row" >
            <div className='col-12' style={{margin:'.25em auto'}}>        
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Cloutics delivers digital transformation, customized business solutions and talent services to enterprises. 
                    We build technology solutions for business processes and deliver end-to-end, business software solutions for 
                    industry verticals that enable companies to increase efficiency, improve performance and build competitive 
                    advantage. We achieve that through the convergence of digital cross channel user experience, cloud, mobility, 
                    insightful data and internet-of-things backed by scalable underlying technology platforms. 
                </p> 
                
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    We help enterprises build solutions for their business problems using low-code / no-code development environment. 
                    For businesses, seeking greater financial visibility and operational 
                    productivity, we enable them by provisioning ERP solutions and empowering them to move to the cloud. 
                    We provide consulting, customization and systems integration services for CRM & ERP implementations.
                </p> 


                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Our talent services team identify the best talent from across the globe and deploy them for client services and 
                    projects. 
                </p>

                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>We believe</p>
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'1em auto'}}>
                    <MiniCard className='col-6'  
                    	cardTitle="People and Process are the fundamental strength of an organization" />
                    <MiniCard className='col-6'  
                    	cardTitle="Innovation is real progress" />
                    <MiniCard className='col-6'  
                    	cardTitle="A thoughtful and committed team can change the way, the world operates" />
                    <MiniCard className='col-6'  
                    	cardTitle="Change is the only constant" />    
                </div>
                
            
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>Know about what we do</p>
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'1em auto'}}>
                    <MicroCard className='col-6 col-12-small' 
                                cardTitle="Transformation" cardImage={TransformImage} 
                                cardLink='/transform-your-business'/>
                    <MicroCard className='col-6 col-12-small' 
                            cardTitle="Solutions on the cloud" cardImage={SolutionsImage} 
                            cardLink='/business-solutions-on-the-cloud'/>
                    <MicroCard className='col-6 col-12-small' 
                        cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                        cardLink='/public-sector-services'/>
                    <MicroCard className='col-6 col-12-small' 
                            cardTitle="Talent Services" cardImage={TalentImage} 
                            cardLink='/talent-services'/>
                    <MicroCard className='col-6 col-12-small' 
                            cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                            cardLink='/lowcode-nocode'/>
                    <MicroCard className='col-6 col-12-small' 
                        cardTitle="Modernize" cardImage={ModernizeImage} 
                        cardLink='/modernize-solutions'/>
                    <MicroCard className='col-6 col-12-small' 
                            cardTitle="Enable the Cloud" cardImage={CloudImage} 
                            cardLink='/enable-the-cloud'/>
                </div>

                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>Locations</p>
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                        We cater to our North American clients from US and Canadian offices.
                        Our India based location works as the offshore center & COE for our US and Canadian offices, and also directly works with our Indian and Middle Eastern clients.
                    </p>
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'1em auto'}}>
                    <MicroCard className='col-12' 
                        cardTitle= {reachCloutics}/>
                    <Card className='col-4' cardTitle="USA" >
                        <h4 style={{marginBottom:'.25em'}}>Cloutics Inc</h4>
                        <h4 style={{fontWeight:300}}>
                            2015 Lincoln Hwy,<br/>
                            Ste 140 <br/>
                            Edison, NJ 08817 USA <br />
                            
                        </h4>
                    </Card>
                    <Card className='col-4' cardTitle="India" >
                        <h4 style={{marginBottom:'.25em'}}>Cloutics Pvt Ltd</h4>
                        <h4 style={{fontWeight:300}}>
                            16/24 Brindhavan 1st Cross St.,<br/>
                            W.Mambalam, <br />
                            Chennai TN 600 033 IN 
                        </h4>
                    </Card>
                    <Card className='col-4' cardTitle="Canada" >
                        <h4 style={{marginBottom:'.25em'}}>Cloutics Inc</h4>
                        <h4 style={{fontWeight:300}}>
                            1225 Kennedy Road<br/> Ste 2000/2014 <br/>
                            Scarborough, ON M1P 4Y1 CA<br />
                        </h4>
                    </Card>
                    
                    
                </div>

                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>Career & Development</p>
                
                    <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                        Attracting and developing the best talent is always an important area of focus for Cloutics, 
                        as we believe that our people are not only our greatest asset but also our biggest 
                        competitive advantage.
                        Visit our <mark><Link to='/career'>career</Link></mark> section to know more.
                    </p> 
                
                
            </div>
        
            
        </div>
    </div>
  </Layout>
);
export default AboutPage;